import { Checkbox, CheckboxGroup, Label } from '@troon/ui';
import { For } from 'solid-js';
import { createAsync, useSearchParams } from '@solidjs/router';
import { courseTypeString } from '../../../components/facility/type-strings';
import { FacilityBrand, gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import type { FacilityType } from '../../../graphql';

type Search = {
	access?: string;
	rewards?: string;
	brands?: string;
	cursor?: string;
	lat?: string;
	lon?: string;
	radius?: string;
	regionIds?: string;
	types?: string;
};

export function CourseFilters() {
	const [search, setSearch] = useSearchParams<Search>();

	return (
		<div class="flex flex-col gap-8">
			<CheckboxGroup name="type">
				<Label class="text-lg font-semibold">Facility type</Label>
				<For each={Object.entries(courseTypeString)}>
					{([key, label]) => (
						<Checkbox
							value={key}
							checked={search.types?.split(',').includes(key)}
							onInput={(e) => {
								const types = search.types?.split(',') ?? [];
								if (e.currentTarget.checked) {
									types.push(e.currentTarget.value);
								} else {
									types.splice(types.indexOf(e.currentTarget.value), 1);
								}
								setSearch({ types: types.join(','), cursor: undefined }, { replace: true });
							}}
						>
							<Label>{label}</Label>
						</Checkbox>
					)}
				</For>
			</CheckboxGroup>

			<CheckboxGroup name="programs">
				<Label class="text-lg font-semibold">Troon programs</Label>
				<For each={Object.entries(programs)}>
					{([key, label]) => (
						<Checkbox
							value={key}
							checked={search[key as keyof Search] === 'true'}
							onInput={(e) => {
								setSearch({ [key]: e.currentTarget.checked ? true : undefined, cursor: undefined }, { replace: true });
							}}
						>
							<Label>{label}</Label>
						</Checkbox>
					)}
				</For>
			</CheckboxGroup>

			<CheckboxGroup name="brand">
				<Label class="text-lg font-semibold">Brand</Label>
				<For each={Object.values(FacilityBrand)}>
					{(brand) => (
						<Checkbox
							value={brand}
							checked={search.brands?.split(',').includes(brand)}
							onInput={(e) => {
								const brands = search.brands?.split(',') ?? [];
								if (e.currentTarget.checked) {
									brands.push(e.currentTarget.value);
								} else {
									brands.splice(brands.indexOf(e.currentTarget.value), 1);
								}
								setSearch({ brands: brands.join(','), cursor: undefined }, { replace: true });
							}}
						>
							<Label>{brandString[brand]}</Label>
						</Checkbox>
					)}
				</For>
			</CheckboxGroup>
		</div>
	);
}

const brandString: Record<FacilityBrand, string> = {
	[FacilityBrand.ObSports]: 'OBSports',
	[FacilityBrand.TroonGolf]: 'Troon Golf',
	[FacilityBrand.TroonPrive]: 'Troon Privé',
	[FacilityBrand.Icon]: 'Icon',
	[FacilityBrand.IndigoSports]: 'Indigo Sports',
	[FacilityBrand.TroonGolfIntl]: 'Troon Golf International',
	[FacilityBrand.TroonPriveIntl]: 'Troon Privé International',
	[FacilityBrand.None]: 'Others',
};

const programs: Record<'access' | 'rewards', string> = {
	access: 'Troon Access',
	rewards: 'Troon Rewards',
};

const listQuery = gql(`
	query exploreFacilities(
		$cursor: String
		$limit: Int
		$regionIds: [String!]
		$location: FacilityLocationInput
		$macroRegionIds: [String!]
		$types: [FacilityType!]
		$brands: [FacilityBrand!]
		$supportsTroonAccess: Boolean
		$supportsTroonRewards: Boolean
	) {
		facilities: facilitiesV3(
			cursor: $cursor
			limit: $limit
			location: $location
			regionIds: $regionIds
			macroRegionIds: $macroRegionIds
			types: $types
			brands: $brands
			supportsTroonAccess: $supportsTroonAccess
			supportsTroonRewards: $supportsTroonRewards
		) {
			facilities {
				...FacilityListCard
			}
			cursor
		}
	}`);

const getFacilities = cachedQuery(listQuery, {
	onError: (e) => {
		return e.message;
	},
});

export function createFilteredFacilities() {
	const [search] = useSearchParams<Search>();
	const facilities = createAsync(
		() =>
			getFacilities({
				cursor: search.cursor,
				location:
					search.lat && search.lon
						? {
								latitude: parseFloat(search.lat),
								longitude: parseFloat(search.lon),
								radiusMiles: parseInt(search.radius ?? '50', 10) ?? 50,
							}
						: undefined,
				types: search.types?.trim() ? (search.types.split(',') as Array<FacilityType>) : undefined,
				supportsTroonAccess: search.access ? search.access === 'true' : undefined,
				supportsTroonRewards: search.rewards ? search.rewards === 'true' : undefined,
				brands: search.brands?.trim() ? (search.brands.split(',') as Array<FacilityBrand>) : undefined,
				regionIds: search.regionIds?.trim() ? search.regionIds.split(',') : undefined,
				limit: 12,
			}),
		{ deferStream: true },
	);

	return facilities;
}
